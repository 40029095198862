
import {
  defineComponent, computed
} from 'vue'
import ServicesNumbersBuyWrapper from '@/components/pages/services/numbers/buy/ServicesNumbersBuyWrapper.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { ResultTableItemType } from '@/definitions/shared/types'
import { useBreakpoints } from '@/compositions/breakpoints'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'

export default defineComponent({
  components: {
    DetailsTable,
    ServicesNumbersBuyWrapper,
    TmButton,
  },
  setup() {
    const { isXsMax } = useBreakpoints()

    const activateButtonLabel = computed(() => isXsMax.value ? 'Auto-recharge' : 'Activate Auto-recharge')

    const tableValue: ResultTableItemType[] = [
      {
        label: 'Dedicated number',
        value: '+37259120726 (Two-way SMS)',
      },
      {
        label: 'Sub-account',
        value: 'Jon Doe (jon.doe@gmail.com)',
      },
      {
        label: 'Calls will be forwarded to',
        value: '+37259154736',
      },
      {
        label: 'Greeting',
        value: '',
        slot: 'greeting',
      },
      {
        label: 'Forwarding price',
        value: '$0.1880 / minute',
      },
      {
        label: 'Number price',
        value: '$3.80 / month',
      },
      {
        label: 'Next renewal date',
        value: '',
        slot: 'next-renewal-date',
      },
    ]

    return {
      tableValue,
      activateButtonLabel,
    }
  },
})
