
import {
  defineComponent, ref
} from 'vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import PageStep from '@/components/layout/PageStep.vue'
import type { StepperStep } from '@/definitions/shared/types'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'

export default defineComponent({
  components: {
    WhiteBlock,
    PageStep,
    TmButton,
    TmAlert,
  },
  props: {
    currentStepIndex: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
  },
  setup() {
    const steps = ref<StepperStep[]>([
      { name: 'Number', to: 'base.services.numbers.buy.step1' },
      { name: 'Call forwarding', to: 'base.services.numbers.buy.step2' },
      { name: 'Finish', to: 'base.services.numbers.buy.step3' },
    ])

    return {
      steps,
    }
  },
})
